<template>
  <div
    v-if="isEditorMode || showInfo"
    class="m-auto flex size-full min-h-[530px] max-w-[242px] flex-col items-center justify-center break-words text-center text-14 text-neutral-900"
    :style="textStyle"
  >
    <div class="flex flex-col gap-32 rounded-16 bg-[#F5F5F5] p-24">
      <template v-if="isEditorMode">
        <span class="font-semibold">{{ translate('generate.pages.payment.noPaymentMethod', locale) }}</span>
      </template>
      <template v-else>
        <svg
          width="80"
          height="69"
          class="m-auto"
          viewBox="0 0 80 69"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_18948_46629)">
            <path
              opacity="0.5"
              d="M34.4851 68.9848C53.5306 68.9848 68.9701 53.5453 68.9701 34.4997C68.9701 15.4541 53.5306 0.0146484 34.4851 0.0146484C15.4395 0.0146484 0 15.4541 0 34.4997C0 53.5453 15.4395 68.9848 34.4851 68.9848Z"
              fill="#E7EAEE"
            />
            <path
              d="M73.4831 19.11V50.5627C73.4831 53.7879 70.8696 56.4025 67.645 56.4025H18.8224C15.5973 56.4025 12.9844 53.7879 12.9844 50.5627V19.11C12.9844 15.8849 15.5979 13.2703 18.8224 13.2703H67.6445C70.8696 13.2703 73.4825 15.8849 73.4825 19.11H73.4831Z"
              fill="white"
              stroke="#818A9C"
              stroke-width="1.1807"
              stroke-miterlimit="10"
            />
            <path d="M72.8996 21H13.5996V32H72.8996V21Z" fill="#B4BFC5" />
            <path d="M39.4809 40.4727H20.7969V43.5865H39.4809V40.4727Z" fill="#E7EAEE" />
            <path d="M39.4809 45.5544H20.7969V48.6683H39.4809V45.5544Z" fill="#E7EAEE" />
            <path
              d="M62.6453 40.0203H54.4902C52.8841 40.0203 51.582 41.3223 51.582 42.9284V46.0294C51.582 47.6355 52.8841 48.9375 54.4902 48.9375H62.6453C64.2514 48.9375 65.5534 47.6355 65.5534 46.0294V42.9284C65.5534 41.3223 64.2514 40.0203 62.6453 40.0203Z"
              fill="#E6E6E6"
            />
            <path
              d="M63.5037 67.492C72.6141 67.492 79.9995 60.1065 79.9995 50.9961C79.9995 41.8857 72.6141 34.5002 63.5037 34.5002C54.3933 34.5002 47.0078 41.8857 47.0078 50.9961C47.0078 60.1065 54.3933 67.492 63.5037 67.492Z"
              fill="#E13023"
            />
            <path
              d="M69.2398 58.3433C68.8274 58.3433 68.4149 58.1863 68.1003 57.8717L56.6282 46.399C55.9989 45.7698 55.9989 44.7498 56.6282 44.1206C57.2574 43.4914 58.2773 43.4914 58.9065 44.1206L70.3787 55.5933C71.0079 56.2226 71.0079 57.2425 70.3787 57.8717C70.0641 58.1863 69.6517 58.3433 69.2392 58.3433H69.2398Z"
              fill="white"
            />
            <path
              d="M57.7676 58.3433C57.3552 58.3433 56.9428 58.1863 56.6282 57.8717C55.9989 57.2425 55.9989 56.2226 56.6282 55.5933L68.1003 44.1206C68.7295 43.4914 69.7495 43.4914 70.3787 44.1206C71.0079 44.7498 71.0079 45.7698 70.3787 46.399L58.9065 57.8717C58.5919 58.1863 58.1795 58.3433 57.7671 58.3433H57.7676Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_18948_46629">
              <rect
                width="80"
                height="68.9707"
                fill="white"
                transform="translate(0 0.0146484)"
              />
            </clipPath>
          </defs>
        </svg>
        <div class="font-semibold">
          {{ translate('generate.pages.payment.noPaymentMethodSupported', locale) }}
        </div>
        <div>
          {{ translate('generate.pages.payment.noPaymentMethodSupportedDescription', locale) }}
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { watch, ref, computed, type PropType } from 'vue';
import { useTranslate } from '@shared/composable/useTranslate';
import { $wait } from '@shared/utils/wait';
import type { PageOptions } from '@shared/types/model';

const { translate } = useTranslate();

const props = defineProps({
  isEditorMode: { type: Boolean, default: true },
  locale: { type: String, default: '' },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
});

const showInfo = ref(false);
const isGettingPackage = computed(() => $wait.is('initPackages'));

const textStyle = computed(() => {
  const fontFamily = props.pageOptions.fontFamily;
  return {
    fontFamily
  };
});

watch(
  () => isGettingPackage.value,
  (val) => {
    if (!val) showInfo.value = true;
  }
);
</script>
